import {Controller} from 'stimulus';
import smoothscroll from 'smoothscroll-polyfill';

export default class extends Controller {
  static values = {destination: String}

  connect() {
    smoothscroll.polyfill();
  }

  to() {
    var that = this;
    setTimeout(function () {
      that.destination = document.getElementById(that.destinationValue);
      if (that.destination) {
        that.destination.scrollIntoView({behavior: 'smooth'});
      }
    }, 750);
  }
}
