import {Controller} from 'stimulus';
import Dropzone from 'dropzone';

export default class extends Controller {
  static targets = ['fileInput', 'imageWrap', 'image', 'noImageWrap', 'editButton', 'previewImage', 'spinner'];

  connect() {
    const controller = this;
    this.dropZone = new Dropzone(this.element, {
      addRemoveLinks: true,
      url: this.data.get('uploadUrl'),
      paramName: this.data.get('fieldName'),
      maxFiles: 1,
      maxFilesize: 5, // MB (client side validation)
      acceptedMimeTypes: 'image/jpeg,image/png',
      disablePreviews: true,
      clickable: (this.editButtonTargets || true),
      dictCancelUpload: this.data.get('cancelUploadLabel'),
      dictRemoveFile: this.data.get('removeFileLabel'),
      previewTemplate:
        `<div class="dz-preview dz-file-preview">
        <div class="dz-image"><img data-dz-thumbnail /></div>
        <div class="dz-details">
          <div class="dz-size"><span data-dz-size></span></div>
          <div class="dz-filename"><span data-dz-name></span></div>
        </div>
        <div class="dz-progress bg-danger">
          <span class="dz-upload" data-dz-uploadprogress></span>
        </div>
        <div class="dz-error-message"><span data-dz-errormessage></span></div>
        <div class="dz-success-mark">
          <svg
            width="54"
            height="54"
            viewBox="0 0 54 54"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.2071 29.7929L14.2929 25.7071C14.6834 25.3166 15.3166 25.3166 15.7071 25.7071L21.2929 31.2929C21.6834 31.6834 22.3166 31.6834 22.7071 31.2929L38.2929 15.7071C38.6834 15.3166 39.3166 15.3166 39.7071 15.7071L43.7929 19.7929C44.1834 20.1834 44.1834 20.8166 43.7929 21.2071L22.7071 42.2929C22.3166 42.6834 21.6834 42.6834 21.2929 42.2929L10.2071 31.2071C9.81658 30.8166 9.81658 30.1834 10.2071 29.7929Z"
            />
          </svg>
        </div>
        <div class="dz-error-mark">
          <svg
            width="54"
            height="54"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="7.5" fill="#fff" />
            <path
              d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm2.52,12.19,2.86,2.86a1.07,1.07,0,0,1,0,1.51h0l-.76.76a1.07,1.07,0,0,1-1.51,0h0l-2.86-2.86a.28.28,0,0,0-.38,0L9,17.33a1.07,1.07,0,0,1-1.51,0h0l-.76-.76a1.07,1.07,0,0,1,0-1.51h0l2.86-2.86a.28.28,0,0,0,0-.38L6.67,9a1.07,1.07,0,0,1,0-1.51h0l.76-.81a1.07,1.07,0,0,1,1.51-.06L9,6.67l2.86,2.86a.28.28,0,0,0,.38,0L15.1,6.67a1.07,1.07,0,0,1,1.51,0h0l.76.76a1.07,1.07,0,0,1,0,1.51h0l-2.86,2.86A.28.28,0,0,0,14.52,12.19Z"
              fill="#dc3545"
            />
          </svg>
        </div>
      </div>`,
    });

    this.dropZone.on('sending', function(file, xhr, formData) {
      // preview image from local file system
      const filePreviewUrl = URL.createObjectURL(file);
      that.imageTarget.src = filePreviewUrl;

      if (controller.hasPreviewImageTarget) {
        controller.previewImageTarget.src = filePreviewUrl;
      }

      // send authenticity_token along with the file as POST data.
      formData.append('authenticity_token', document.getElementById(that.data.get('authenticityTokenId') || 'authenticity_token').value);

      that.showSpinner(true);
      that.disableSaveButton(true);
    });

    const that = this;

    this.dropZone.on('success', function(file, response) {
      that.showSpinner(false);
      that.disableSaveButton(false);

      controller.dropZone.removeFile(file);
      that.imageWrapTarget.classList.remove('d-none');
      that.noImageWrapTarget.classList.add('d-none');

      if (that.hasFileInputTarget) {
        that.fileInputTarget.value = response.image_url;
      }
      if (that.hasFileInputDetachTarget) {
        that.fileInputDetachTarget.value = false;
      }
      if (that.data.get('nextSelector')) {
        $(that.data.get('nextSelector')).removeClass('d-none');

        if (parseInt(that.data.get('imageNumber')) >= (parseInt(that.data.get('maxImages')) - 1)) {
          $(that.data.get('addButtonSelector')).addClass('d-none');
        } else {
          $(that.data.get('addButtonSelector')).removeClass('d-none');
        }
      }
    });

    this.dropZone.on('error', function(file, response) {
      that.showSpinner(false);
      that.disableSaveButton(false);

      let error = 'error';
      if (response.includes('File is too big')) {
        error = that.data.get('errorMaxSize');
      } else {
        error = that.data.get('errorFormat');
      }
      showToast(error, 'alert alert-danger');
      $(file.previewElement).find('.dz-error-message').text(error);

      that.imageWrapTarget.classList.add('d-none');
      that.noImageWrapTarget.classList.remove('d-none');
    });

    // to keep track of concurrent uploads
    window.dropzoneUploads ||= 0;
  }

  disableSaveButton(disabled) {
    if (this.data.get('saveButtonSelector')) {
      if (disabled) {
        document.querySelectorAll(this.data.get('saveButtonSelector')).forEach((button) => {
          button.disabled = true;
        });
        window.dropzoneUploads += 1;
      } else {
        window.dropzoneUploads = Math.max(0, window.dropzoneUploads - 1);
        if (window.dropzoneUploads == 0) {
          document.querySelectorAll(this.data.get('saveButtonSelector')).forEach((button) => {
            button.disabled = false;
          });
        }
      }
    }
  }

  showSpinner(visible) {
    if (this.hasSpinnerTarget) {
      if (visible) {
        this.spinnerTarget.classList.remove('d-none');
      } else {
        this.spinnerTarget.classList.add('d-none');
      }
    }
  }

  delete(e) {
    const result = confirm(this.data.get('deleteConfirm'));
    if (result == true) {
      const that = this;
      fetch(this.data.get('deleteUrl'))
          .then((response) => {
            if (!response.ok) {
              console.debug('could not delete image');
            } else {
              console.debug('image deleted');
              that.noImageWrapTarget.classList.remove('d-none');
              that.imageWrapTarget.classList.add('d-none');
              if (that.fileInputTarget) {
                that.fileInputTarget.value = '';
              }
            }
          });
    }
    e.stopPropagation();
  }

  showFilePicker(e) {
    e.preventDefault();
    const editButton = this.editButtonTargets[0];
    editButton.click();
  }
}
